<template>
	<el-dialog title="修改密码" :visible.sync="dialogVisible" width="466px" :close-on-click-modal="false">
		<el-form :rules="rules" ref="form" :model="form" label-position="top" class="demo-ruleForm clear-fix">
			<el-form-item label="原密码：" prop="oldPassword">
				<el-input size="small" type="password" v-model.trim="form.oldPassword"></el-input>
			</el-form-item>
			<el-form-item label="新密码：" prop="newPassword">
				<el-input size="small" type="password" v-model.trim="form.newPassword"></el-input>
			</el-form-item>
			<el-form-item label="确认新密码：" prop="newPasswordConfirm">
				<el-input size="small" type="password" v-model.trim="form.newPasswordConfirm"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="onSave('ruleForm')">保 存</el-button>
			<el-button @click="resetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { Session } from '@/utils/storage.js';
export default {
	data() {
		var checkPwd = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value === this.form.oldPassword) {
				callback(new Error('新密码不能和原密码一致!'));
			} else {
				callback();
			}
		};
		var checknewPasswordConfirm = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.form.newPassword) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			dialogVisible: false,
			activeName: 'first',
			form: {
				oldPassword: '',
				newPassword: '',
				newPasswordConfirm: '',
			},
			rules: {
				name: [{ required: true, message: '此项为必填项', trigger: 'blur' }],
				oldPassword: [{ required: true, message: '此项为必填项', trigger: 'blur' }],
				newPassword: [
					{
						required: true,
						validator: checkPwd,
						trigger: 'blur',
					},
				],
				newPasswordConfirm: [{ required: true, validator: checknewPasswordConfirm, trigger: 'blur' }],
			},
		};
	},
	mounted() {},
	methods: {
		onSave() {
			this.$refs.form.validate((valid) => {
				if (valid) {
					this.$http.put(this.api.modifyPasswords, this.form).then((res) => {
						if (res.data && res.data.success) {
							this.dialogVisible = false;
							this.$message.success(res.data.message);
							// 清除缓存/token等
							Session.clear();
							// 使用 reload 时，不需要调用 resetRoute() 重置路由
							window.location.reload();
						}
					});
				}
			});
		},
		resetForm() {
			this.$refs.form.resetFields();
			this.dialogVisible = false;
			this.form = {
				// name: '',
				oldPassword: '',
				newPassword: '',
				newPasswordConfirm: '',
			};
		},
	},
};
</script>passwordUpdate

<style lang="scss" scoped>
/deep/ .el-dialog__header {
	padding: 28px;
	color: #333;
}
/deep/.el-dialog .el-dialog__body {
	padding: 0 28px 28px !important;
}
/deep/ .el-input {
	width: 100%;
	height: 30px;
	line-height: 30px;
}
/deep/ .el-form-item {
	margin-bottom: 20px;
}
/deep/ .el-form-item__label {
	padding-right: 5px;
	line-height: 14px;
	height: 14px;
	margin-bottom: 8px;
}
/deep/ .validateCode {
	.el-form-item__content {
		.el-input {
			width: calc(100% - 130px);
			margin-right: 16px;
		}
		.changeImg {
			text-align: center;
			letter-spacing: 2px;
			// width: 100px;
			color: #1db9b1;
			cursor: pointer;
			// padding: 6px 16px !important;
			margin-right: 0;
		}
	}
}
.changeCheck {
	text-align: end;
	margin-top: 8px;
	color: #1db9b1;
	cursor: pointer;
}
/deep/.el-dialog .el-dialog__footer {
	text-align: center;
	padding-top: 0;
	padding-bottom: 28px;
}
</style>
